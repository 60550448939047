import React, { useEffect, useState } from "react";
import { Button, Checkbox, message, Switch, Table, Tabs } from "antd";

import "./Permission.css";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Service from "../../service";
// import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const columns = [
  {
    title: "Permission Lists",
    dataIndex: "Permission_Lists",
    width: '19%'
  },
  {
    title: "Actions",
    dataIndex: "Actions",
    width: '19%',

  }]
const Permissionmanger = () => {
  const [permissionListData, setPermissionListData] = useState([]);
  const [bodyData, setBodyData] = useState([])
  const [initialPermissionListData, setInitialPermissionList] = useState([]);
  const [permissions, setPermissions] = useState({});
  const nuseNavigate = useHistory()
  const dispatch = useDispatch();
  const { id, roleid, pms_role_id } = useParams();
const [checked,setCkecked]=useState(Boolean)

  //HRMS  FUNCTIONS START
  useEffect(() => {
    getRolesPermission();
  }, []);

  useEffect(() => {
    if (initialPermissionListData.length > 0) {
      const groupedPermissions = initialPermissionListData.reduce((acc, ele) => {
        const { name, _id } = ele;
        if (!acc["General"]) {
          acc["General"] = [];
        }
        acc["General"].push({
          name,
          _id,
          permissions: {
            View: ele.read,
            Create: ele.create,
            Update: ele.update,
            Delete: ele.delete,
            All: (ele.read && ele.create && ele.update && ele.delete) ? true : false
          },
        });
        return acc;
      }, {});
      setPermissions(groupedPermissions);
    }
  }, [initialPermissionListData]);



  const getRolesPermission = async () => {
    try {
      dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: `${Service.getRolesPermission}/${roleid}/${id}`,
      });
      if (response.data) {
        setInitialPermissionList(response.data.data);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      // console.log(error);
      message.error('');

    }
  };

  const onPermissionChange = (checked, permissionType, categoryId, permissionId) => {
    setPermissions((prevPermissions) => {
      if (!prevPermissions[categoryId]) return prevPermissions;

      const updatedCategory = prevPermissions[categoryId].map((permission) => {
        if (permission._id === permissionId) {
          const updatedPermissions = { ...permission.permissions, [permissionType]: checked };

          if (permissionType === 'All') {
            for (let key in updatedPermissions) {
              if (key !== 'All') {
                updatedPermissions[key] = checked;
              }
            }
          }

          const allChecked = ['View', 'Create', 'Update', 'Delete'].every(type => updatedPermissions[type]);
          updatedPermissions.All = allChecked;

          return { ...permission, permissions: updatedPermissions };
        }
        return permission;
      });

      return { ...prevPermissions, [categoryId]: updatedCategory };
    });
  };

  const handleCheckboxChange = (event, categoryId, permissionId) => {
    const { name, checked } = event.target;
    onPermissionChange(checked, name, categoryId, permissionId);
  };

  const handleFormSubmit = async () => {
    const formattedPermissions = Object.values(permissions)
      .flat()
      .map((permission) => ({
        resource_id: permission._id,
        read: permission.permissions.View,
        create: permission.permissions.Create,
        update: permission.permissions.Update,
        delete: permission.permissions.Delete,
      }));

    const reqBody = {
      admin_id: id,
      role_id: roleid,
      resources: formattedPermissions,
    };
    try {
      dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.putMethod,
        api_url: `${Service.updatePermission}/${roleid}/${id}`,
        body: reqBody,
      });
      if (response.data) {
        getRolesPermission();
        // console.log("handleSubmit", response.data.data);
        nuseNavigate.push('/adminmaster')
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      // console.log(error);
      message.error('');

    }
  };
  //HRMS  FUNCTIONS END



  //PMS  FUNCTIONS START
  const onPermissionChangePMS = async (checked, id1) => {
    console.log(checked,'chkc');
    try {
      dispatch(showAuthLoader());
      
      const updatedPermissionListData = permissionListData.map(item => {
        if (item._id === id1) {
          return { ...item, isAccess: checked.target.checked };
        }
        return item;
      });
      console.log(updatedPermissionListData,'updatedPermissionListData');
      setPermissionListData(updatedPermissionListData);

      const reqBody = {
        resource_ids: updatedPermissionListData
          .filter(item => item.isAccess)
          .map(item => item._id),
        admin_id: id,
        pms_role_id: pms_role_id,
      };
      setBodyData(reqBody)
      dispatch(hideAuthLoader());
    } catch (error) {
      // console.log(error);
      message.error('');

    }
  };

  const Update_PMS_DAta = async () => {
    try {
      const response = await Service.makeAPICall({
        methodName: Service.putMethod,
        api_url: Service.pms_roles_permissions_Update,
        body: bodyData,
      });
      // console.log(response, 'FNLPUT');
      if (response.data.status == 200) {
        message.success(response.data.message)
        nuseNavigate.push('/adminmaster')

      } else if (response.data.status == 404) {

        message.error(response.data.message)
      }
    } catch (error) {
      // console.log(error);
      message.error('');

    }
  }

  const data = permissionListData.map((cvl) => {
    return {
      key: cvl._id,
      Permission_Lists: cvl.name.replace('_', '  '),
      Actions: (<>
        {/* <Switch  onChange={ (checked)=>onChange(checked,cvl?._id) } /> */}
        <Checkbox
          checked={cvl?.isAccess}
          // {cvl?.isAccess}
          onChange={(checked) => {
            onPermissionChangePMS(checked, cvl?._id)
          }}
          size="small"
        />
     
      </>)
    }
  })
  const getIdRoleId = async () => {
    try {
      dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: `${Service.pms_roles_permissions}/${pms_role_id}/${id}`
      });
      // console.log(response, 'delete')
      if (response.data && response.data.data && response.status == 200) {
        setPermissionListData(response.data.data)
      }
      dispatch(hideAuthLoader());
     
    } catch (error) {
      // console.log(error);
      message.error('');

    }
  }

  useEffect(() => {
    getIdRoleId()
  }, [pms_role_id])
  //

  //PMS  FUNCTIONS END

  return (<>
    <div className="permission-modal-content-wrapper">
      <div className="permission-content-header">
        <div className="permission-content-title">
          <Tabs defaultActiveKey="1" >
            <Tabs.TabPane tab="HRMS Permission List" key="1">
              <div className="permission-grid">
                {Object.keys(permissions).map((category) => (
                  <>
                    <div key={category}>
                      {permissions[category]?.slice(0, 33).map((permission) => (
                        <div key={permission._id} className="permission-container">
                          <h4>{permission?.name}</h4>
                          <div className="permission-checkbox-group">
                            {Object.keys(permission.permissions).map((permType) => (
                              <label key={permType} className="permission-checkbox">
                                {permType}
                                <input
                                  type="checkbox"
                                  name={permType}
                                  checked={permission.permissions[permType]}
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event,
                                      category,
                                      permission._id
                                    )
                                  }
                                />
                              </label>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div key={category}>
                      {permissions[category]?.slice(33).map((permission) => (
                        <div key={permission._id} className="permission-container">
                          <h4>{permission?.name}</h4>
                          <div className="permission-checkbox-group">
                            {Object.keys(permission.permissions).map((permType) => (
                              <label key={permType} className="permission-checkbox">
                                {permType}
                                <input
                                  type="checkbox"
                                  name={permType}
                                  checked={permission.permissions[permType]}
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event,
                                      category,
                                      permission._id
                                    )
                                  }
                                />
                              </label>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ))}
              </div>
              <div style={{ marginTop: "20px", textAlign: "center", display: 'flex', gap: '10px', justifyContent: 'center' }}>
                <Link to='/adminmaster'>
                  <Button type="primary" className="btn">
                    Back
                  </Button>
                </Link>
                <Button type="primary" onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="PMS Permission List" key="2">


              <div className="permission-grid"  >
                <div style={{ width: '100%' }}>
                  <Table
                    dataSource={data}
                    columns={columns}
                    style={{ textTransform: "capitalize" }}
                  />
                  <div style={{ marginTop: "20px", textAlign: "center", display: 'flex', gap: '10px', justifyContent: 'center' }}>
                    <Link to='/adminmaster'>
                      <Button type="primary" className="btn">
                        Back
                      </Button>
                    </Link>
                    <Button type="primary" onClick={Update_PMS_DAta} >
                      Submit
                    </Button>
                  </div>
                </div>

              </div>


            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>

    </div></>
  );
};

export default Permissionmanger;
