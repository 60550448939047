import React from "react";
import { Route } from "react-router-dom";
import config from "../settings/config.json";

// import { getRoles } from "../util/hasPermission.js";
import Permissionmanger from "../pages/Adminmaster/Permissionmanger.js";
import { PermissionPMS } from "../pages/PermissionPMS/PermissionPMS.js";

const DashboardModule = React.lazy(() => import("../pages/Dashbaord/index"));
const Adminmaster = React.lazy(() => import("../pages/Adminmaster/Adminmaster"));
const  AddDat= React.lazy(() => import("../pages/AddDAtaRoute/AddDat")); 
const Update=React.lazy(()=>import('../pages/Update/Update'))
const index = ({ match, userPermission }) => {
  const routeArray = [
   
    {
      path: "dashboard",
      component: DashboardModule,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    },

    {
      path :"adminmaster",
      component:Adminmaster,
      roleName: [
        config.PMS_ROLES.SUPER_ADMIN,
        config.PMS_ROLES.ADMIN,
        config.PMS_ROLES.USER,
        config.PMS_ROLES.PC,
        config.PMS_ROLES.TL,
      ],
    }
   ,
   {
    path :"addData",
    component:AddDat,
    roleName: [
      config.PMS_ROLES.SUPER_ADMIN,
      config.PMS_ROLES.ADMIN,
      config.PMS_ROLES.USER,
      config.PMS_ROLES.PC,
      config.PMS_ROLES.TL,
    ],
  },
  {
    path :"Update/:id",
    component:Update,
    roleName: [
      config.PMS_ROLES.SUPER_ADMIN,
      config.PMS_ROLES.ADMIN,
      config.PMS_ROLES.USER,
      config.PMS_ROLES.PC,
      config.PMS_ROLES.TL,
    ],
  },
  {
    path :"permission/:roleid/:id/:pms_role_id",
    component:Permissionmanger,
    roleName: [
      config.PMS_ROLES.SUPER_ADMIN,
      config.PMS_ROLES.ADMIN,
      config.PMS_ROLES.USER,
      config.PMS_ROLES.PC,
      config.PMS_ROLES.TL,
    ],
  },
  {
    path :"permissionPMS/:roleid/:id",
    component:PermissionPMS,
    roleName: [
      config.PMS_ROLES.SUPER_ADMIN,
      config.PMS_ROLES.ADMIN,
      config.PMS_ROLES.USER,
      config.PMS_ROLES.PC,
      config.PMS_ROLES.TL,
    ],
  }
  ];

  return (
    <>
      {routeArray.map((item, index) => (
        <Route
          exact
          key={index}
          path={`${match.url}${item.path}`}
          render={(
            routeProps // Here routeProps are the props provided by the Route component (match, location, history)
          ) =>
            React.createElement(item.component, { ...routeProps })
            // getRoles(item.roleName) ? (
            //   React.createElement(item.component, { ...routeProps }) // Spread routeProps to pass down to the component
            // ) :  (
            //   <Redirect to="/dashboard" />
            // )
          }
        />
      ))}
    </>
  );
};

export default index;
