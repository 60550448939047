module.exports = {
  notificationType: {
    PROJECT_ASSIGNED: "projectAssign",
    LIST_ASSIGNED: "listAssign",
    TASK_ASSIGNED: "taskAssign",
    TASK_COMMENT_ASSIGNED: "taskCommentsTagged",
    DISCUSSION_SUBSCRIBERS: "discussionSubscribed",
    DISCUSSION_TAGGED_USERS: "discussionTagged",
    BUG_ASSIGNED: "bugsAssigned",
    BUG_COMMENTS: "bugCommentsTagged",
    TASK_LOGGED_HOURS: "loggedHours",
    NOTE_SUBSCRIBERS: "noteSubscribed",
    NOTE_COMMENTS_TAGGED_USERS: "noteCommentsTagged",
    FILE_SUBSCRIBERS: "fileSubscribed",
  },
};
