import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE, SET_FAV_ICON, SET_LOGO,SET_MODEL, SET_PAGE, SET_FORM_FLD } from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

// export const hideMessage = () => {
//   return {
//     type: HIDE_MESSAGE
//   }
// };

export const setFavIcon = (base64) => {
  return {
    type: SET_FAV_ICON,
    payload: base64
  }
}

export const setLogo = (base64) => {
  return {
    type: SET_LOGO,
    payload: base64
  }
}




export const setModelFals = (fal) => {
  return {
    type: SET_MODEL,
    payload: fal
  }
}

export const setPageOn = (fal) => {
  return {
    type: SET_PAGE,
    payload: fal
  }
}

export const setFormFld = (fal) => {
  return {
    type: SET_FORM_FLD,
    payload: fal
  }
}


