import React, { useEffect, useState } from "react";
import { Collapse, Form, Input, Menu, Modal, Popover } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import CustomScrollbars from "../../util/CustomScrollbars";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { FieldTimeOutlined, FolderOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions";
import Service from "../../service";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./SidebarContent.css";
import PropTypes from "prop-types";
import { getRoles } from "../../util/hasPermission";
import ProjectListModal from "../../components/Modal/ProjectListModal"

function SidebarContent({ setSidebarCollapsed }) {
  const { themeType } = useSelector(({ settings }) => settings);
  const { pathname } = useSelector(({ common }) => common);

  const location = useLocation();
  const dispatch = useDispatch();

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const [openKeys, setOpenKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [recentList, setRecentList] = useState([])  
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
 

  useEffect(() => {
    if (window.location.pathname == "/organization-view") {
      setSidebarCollapsed(true);
      setOpenKeys([]);
    } else {
      setSidebarCollapsed(false);
    }
  }, [window.location.pathname]);

  // open modal and calling getlisting api
  const showModal = async () => {
    setIsModalOpen(true);
    getProjectListing();
    getVisitedData();
  };



  const getProjectListing = async searchText => {
    try {
      dispatch(showAuthLoader());
      const defaultPayload = {
        pageNo: 1,
        limit: 5,
        search: searchText || "",
        // search: '',
        sortBy: "desc",
        filterBy: "all",
        isSearch: true,
      };
      const reqBody = {
        ...defaultPayload,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        // setSearchEnabled(true);
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getProjectdetails,
        body: reqBody,
      });
      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        setProjectDetails(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addVisitedData = async (projectId) => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.addrecentVisited,
        body: {
          project_id: projectId
        }
      })
      if (response?.data && response?.data?.statusCode == 200) {
        dispatch(hideAuthLoader());
      }
    }
    catch (error) {
      console.log("add project error")
    }
  }

  const getVisitedData = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getrecentVisited,
      })
      if (response?.data && response?.data?.statusCode == 200) {
        dispatch(hideAuthLoader());
        setRecentList(response?.data?.data)
      }
    }
    catch (error) {
      console.log("get project error")
    }
  }


  return (
    <>
   
      <div className="gx-sidebar-content sidebar-menu">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            openKeys={openKeys}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
     
              <Menu.Item
                key="Dashboard"
                className={
                  location.pathname?.includes("/dashboard")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink to="/dashboard">
                  <i className="fi fi-rs-house-chimney"></i>
                  <span>
                    <IntlMessages id="sidebar.dashboard" />
                  </span>
                </NavLink>
              </Menu.Item>

              <Menu.Item
                key="Master"
                className={
                  location.pathname?.includes("/adminmaster")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink to="/adminmaster">
                  {/* <i className="fi fi-rs-house-chimney"></i> */}
                  <i class="fa-solid fa-user-large"></i>                  <span>
                    <IntlMessages id="sidebar.master" />
                  </span>
                </NavLink>
              </Menu.Item>
          
          </Menu>

          <ProjectListModal
            projectDetails={projectDetails}
            recentList={recentList}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            addVisitedData={addVisitedData}
            setIsModalOpen={setIsModalOpen}
            form={form}
            getProjectListing={getProjectListing}
          />

        </CustomScrollbars>
      </div>
    </>
  );
}

SidebarContent.propTypes = {
  setSidebarCollapsed: PropTypes.func.isRequired,
};
export default SidebarContent;
