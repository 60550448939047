import enUS from 'antd/es/locale/en_US'; 
import saMessages from "../locales/ar_SA.json";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: enUS,
  locale: 'ar'
};
export default saLang;
