import enUS from 'antd/es/locale/it_IT';
import saMessages from "../locales/it_IT.json";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: enUS,
  locale: 'it-IT'
};
export default saLang;
