import React from "react";
import { Layout } from "antd";
import Auxiliary from "../../util/Auxiliary";
import { toggleCollapsedSideNav } from "../../appRedux/actions";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "../Sidebar/UserProfile";


const { Header } = Layout;

function Topbar() {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  return (
    <>
      <Header className="top-header top-header-icons">
        { navStyle === NAV_STYLE_DRAWER ||
          ((navStyle === NAV_STYLE_FIXED ||
            navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="fi fi-rr-menu-burger icon-menu"
              onClick={ () => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } }
            />
          </div>
        ) : null }

        { width >= TAB_SIZE ? null : (
          <Auxiliary>
            <div className="topbar-icons">
              {/* <li className="gx-notify fi fi-rr-settings"> */ }
              {/* <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover> */}
              <i className="fi fi-rr-settings"></i>
              {/* </li> */ }
              {/* 
              <li className="gx-msg"> */}
              {/* <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                       content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
              </Popover> */}
              <i className="fi fi-rr-bell"></i>
              {/* </li> */ }
            </div>
          </Auxiliary>
        ) }

        { width >= TAB_SIZE ? (
          <UserProfile />
        ) : (
          <>
            {/* <Auxiliary>
              <li className="gx-user-nav"><UserInfo /></li>
            </Auxiliary> */}
          </>
        ) }
      </Header>
    </>
  );
}

export default Topbar;
