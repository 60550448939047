import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  Modal,
  Popover,
  message,
  Dropdown,
  Space,
  Avatar,
  Tabs,
  Radio,
  Checkbox,
  Menu,
  Upload
} from "antd";
import PropTypes from "prop-types";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EyeTwoTone,
  InboxOutlined,
  MoreOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ProfileImage from "../../assets/images/default_profile.jpg";
import { Link, withRouter, useHistory } from "react-router-dom";
import Service from "../../service";

import {
  userSignOut,
  showAuthLoader,
  hideAuthLoader,
} from "../../appRedux/actions/Auth";
import { FaRegFileArchive } from "react-icons/fa";
import "./UserProfile.css";
import { useSocket } from "../../context/SocketContext";
import { useSocketAction } from "../../hooks/useSocketAction";
import { socketEvents } from "../../settings/socketEventName";
import { checkNotificationType } from "../../util/NotificationTypeCheck";
import moment from "moment";
import { getRoles } from "../../util/hasPermission";
import ImageUpload from "../../components/FileUpload/FileUpload";

function UserProfile() {
  const { authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [emailSetting] = Form.useForm();
  const [apiDetailSetting] = Form.useForm();
  const [generalSetting] = Form.useForm();
  const dispatch = useDispatch();
  const socket = useSocket();
  const { emitEvent, listenEvent, showBrowserNotification } = useSocketAction();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [dataCount, setCountdata] = useState([]);
  const [visible, setVisible] = useState(false);
  // const [visiblePopOver,setIsVisiblePopOver] = useState(false)
  const [flag, setflag] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationReadData, setNotificationReadData] = useState([]);
  const [activeTab, setActiveTab] = useState("unread");
  const [unReadId, setUnReadId] = useState([]);
  const { TabPane } = Tabs;
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [apiId, setApiId] = useState("");
  const [logoModeRadio, setLogoModeRadio] = useState();
  const [fileLists, setFileLists] = useState({
    login_logo: null,
    header_logo: null,
    fav_logo: null,
  });
  const attachmentfileRefs = {
    login_logo: useRef(null),
    header_logo: useRef(null),
    fav_logo: useRef(null),
  };
  const [generalApp, setGeneralApp] = useState([]);
  const [generalAdd, setGeneralAdd] = useState([]);
  const [tempurl, setTempUrl] = useState({
    login_logo: null,
    header_logo: null,
    fav_logo: null,
  });
  const headerLogo = localStorage.getItem("headerLogo");

  const handleTabChange = (activeKey) => {
    if (activeKey == 2) {
      getApiKey();
    }
    // if (activeKey == 3) {
    //   generalSettingApp();
    // }
  };

  const dimensionConstraints = {
    login_logo: { width: 250, height: 50 },
    header_logo: { width: 135, height: 25 },
    fav_logo: { width: 16, height: 16 },
  };

  const handleFileChange = async (e, itemName) => {
    const file = e.target.files[0];
    const allowedExtensions = ["png", "jpg", "jpeg", "svg", "ico"];
    const extension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(extension)) {
      message.error(
        `File "${file.name}" is not a valid image format. Please select PNG, JPG, JPEG, or SVG file.`
      );
      return;
    }

    const dimensions = await getImageDimensions(file);
    const constraints = dimensionConstraints[itemName];
    if (!constraints) {
      message.error(`No dimension constraints found for item "${itemName}".`);
      return;
    }

    if (
      dimensions.width !== constraints.width ||
      dimensions.height !== constraints.height
    ) {
      message.error(`Image "${itemName}" must be exactly ${constraints.width}x${constraints.height} pixels.
       The uploaded image is ${dimensions.width}x${dimensions.height} pixels.
       `);
      return;
    }

    setFileLists((prevLists) => ({
      ...prevLists,
      [itemName]: file,
    }));

    setTempUrl((prevUrls) => ({
      ...prevUrls,
      [itemName]: URL.createObjectURL(file),
    }));
  };

  // Helper function to get image dimensions
  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const handleRemoveFile = (itemName) => {
    setFileLists((prevLists) => ({
      ...prevLists,
      [itemName]: null,
    }));
    setTempUrl((prevUrls) => ({
      ...prevUrls,
      [itemName]: null,
    }));
    if (attachmentfileRefs[itemName].current) {
      attachmentfileRefs[itemName].current.value = null;
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };
  const switchToTab = (tab) => {
    setActiveTab(tab);
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (authUser) {
        await emitEvent(socketEvents.GET_UNREAD_NOTIFICATIONS, {
          user_id: authUser._id,
        });

        await emitEvent(socketEvents.GET_READ_NOTIFICATIONS, {
          user_id: authUser._id,
        });

        const notificationCleanup = listenEvent(
          socketEvents.GET_UNREAD_NOTIFICATIONS,
          (data) => {
            if (isMounted) {
              setNotificationData(data.data);
              setCountdata(data.data.length);
              setUnReadId(notificationData.map((item) => item?._id));
            }
          }
        );
        const notificationReadCleanUp = listenEvent(
          socketEvents.GET_READ_NOTIFICATIONS,
          (data) => {
            if (isMounted) {
              setNotificationReadData(data.data);
            }
          }
        );
        return notificationCleanup, notificationReadCleanUp;
      }
    };

    fetchData();
    const notificationCleanup = listenEvent(
      socketEvents.NOTIFICATIONS,
      (data) => {
        setflag(!flag);
      }
    );
    const notificationReadCleanUp = listenEvent(
      socketEvents.NOTIFICATIONS,
      (data) => {
        setflag(!flag);
      }
    );

    return () => {
      isMounted = false;
      if (notificationCleanup) notificationCleanup();
      if (notificationReadCleanUp) notificationReadCleanUp();
    };
  }, [authUser, history, socket, flag, visible]);

  const notificationMarkAsRead = async (id) => {
    await emitEvent(socketEvents.READ_NOTIFICATIONS, {
      user_id: authUser._id,
      notification_ids: [id],
    });
    setflag(!flag);
  };

  const notificationMarkAllAsRead = async () => {
    await emitEvent(socketEvents.READ_NOTIFICATIONS, {
      user_id: authUser._id,
      notification_ids: unReadId,
    });
    setflag(!flag);
  };

  const goToModuleByNotification = async (
    id,
    notificationID,
    type,
    main_task_id,
    taskId,
    bug_id
  ) => {
    switch (type) {
      case "projectAssign":
        return {
          title: "Assign project",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };

      case "listAssign":
        return {
          title: "Assign new task list",
          url: history.push(
            `/project/app/${id}?tab=${
              checkNotificationType(type).tab
            }&listID=${main_task_id}`
          ),
        };

      case "taskAssign":
        return {
          title: "Assign task",
          url: history.push(
            `/project/app/${id}?tab=${
              checkNotificationType(type).tab
            }&listID=${main_task_id}&taskID=${taskId}`
          ),
        };

      case "taskCommentsTagged":
        return {
          title: "Mention in task comment",
          url: history.push(
            `/project/app/${id}?tab=${
              checkNotificationType(type).tab
            }&listID=${main_task_id}&taskID=${taskId}`
          ),
        };
      case "discussionSubscribed":
        return {
          title: "Subscribe in discussion",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };
      case "discussionTagged":
        return {
          title: "Mention in discussion",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };
      case "bugsAssigned":
        return {
          title: "Assign bug",
          url: history.push(
            `/project/app/${id}?tab=${
              checkNotificationType(type).tab
            }&bugID=${bug_id}`
          ),
        };
      case "bugCommentsTagged":
        return {
          title: "Mention in bug",
          url: history.push(
            `/project/app/${id}?tab=${
              checkNotificationType(type).tab
            }&bugID=${bug_id}`
          ),
        };
      case "loggedHours":
        return {
          title: "Hours logged in task",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };
      case "noteSubscribed":
        return {
          title: "Subscribe in note",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };
      case "noteCommentsTagged":
        return {
          title: "Mention in note",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };
      case "fileSubscribed":
        return {
          title: "Subscribed in files",
          url: history.push(
            `/project/app/${id}?tab=${checkNotificationType(type).tab}`
          ),
        };
      default:
        return {
          title: "You have a notification",
        };
    }
  };

  const changepassword = async (value) => {
    try {
      console.log(value,'valuevalue');
      dispatch(showAuthLoader());
      const reqBody = {
        oldpassword: value?.current_password,
        newPassword: value?.confirm_password,
        user_id: authUser?._id,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.updatePasswordSuperAdmin,
        body: reqBody,
      });
      if (response.data.statusCode === 200) {
        message.success(response?.data?.message);
        setIsModalOpen(false);
        // message.success(response.data.message);

        // dispatch(userSignOut());
        console.log(response,'updatePass');
      } else {
        message.error(response?.data?.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleResetApi = () => {
    const generateRandomKey = () => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 25; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    };
    const newApiKey = generateRandomKey();
    setApiKey(newApiKey);
    updateApiKey(newApiKey, apiId);
  };

  const uploadFiles = async (files, type) => {
    try {
      const formData = new FormData();
      for (const file of files) {
        formData.append("document", file);
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.fileUpload}?file_for=${type}`,
        body: formData,
        options: {
          "content-type": "multipart/form-data",
        },
      });
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handlegeneralSetting = async (values) => {
    const fileAttachment = Object.keys(fileLists)
      .map((key) => ({ key, file: fileLists[key] }))
      .filter(({ file }) => file instanceof File);
    const uploadedPaths = {};

    if (fileAttachment.length > 0) {
      const filesToUpload = fileAttachment.map(({ file }) => file);
      const uploadedFiles = await uploadFiles(filesToUpload, "appSetting");

      if (uploadedFiles.length > 0) {
        fileAttachment.forEach(({ key }, index) => {
          uploadedPaths[key] = uploadedFiles[index].file_path;
        });
      } else {
        return message.error("File not uploaded, something went wrong");
      }
    }

    try {
      dispatch(showAuthLoader());
      const reqBody = {
        title: values.title,
        fav_icon: uploadedPaths.fav_logo || fileLists.fav_logo.name,
        logo_mode: values.logo_mode || logoModeRadio,
        login_logo: uploadedPaths.login_logo || fileLists.fav_logo.name,
        header_logo: uploadedPaths.header_logo || fileLists.fav_logo.name,
      };

      const response = await Service.makeAPICall({
        api_url: Service.addGeneralSetting,
        methodName: Service.postMethod,
        body: reqBody,
      });

      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setGeneralAdd(response?.data?.data);
        generalSettingApp();

        console.log(generalAdd, "add edit api response");
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error, "add general setting error");
    }
  };

  const emailPreference = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.getSettings,
        methodName: Service.getMethod,
      });
      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        let data = response?.data?.data;
        let radioValue = "Immediate";
        if (data?.never) {
          radioValue = "Never";
        } else if (data?.quarterlyMail) {
          radioValue = "four_hours";
        }
        setSelectedRadio(radioValue);
        emailSetting.setFieldsValue({
          projectAssigned: data?.project_assigned,
          discussionSubscribed: data?.discussion_subscribed,
          discussionComments: data?.discussion_tagged,
          tasklistSubscribed: data?.maintask_subscribed,
          taskAssigned: data?.task_assigned,
          taskComments: data?.task_tagged_comments,
          bugAssigned: data?.bug_assigned,
          bugComments: data?.bug_tagged_comments,
          noteAssigned: data?.note_assigned,
          noteComments: data?.note_tagged_comments,
          fileSubscribed: data?.file_subscribed,
          hoursLogged: data?.logged_hours,
          notificationPreference: radioValue,
        });
      }
    } catch (error) {
      console.log(error, "getMethod error");
    }
  };

  const getApiKey = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.getApiKey,
        methodName: Service.getMethod,
      });
      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setApiKey(response?.data?.data?.api_key);
        setApiId(response?.data?.data?._id);
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error, "getMethod error");
    }
  };

  const generalSettingApp = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.getGeneralSetting,
        methodName: Service.getMethod,
      });
      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setGeneralApp(response?.data?.data);
        generalSetting.setFieldValue({
          title: response?.data?.data?.title,
        });
        setFileLists({
          fav_logo: response.data.data.fav_icon
            ? {
                name: response.data.data.fav_icon,
                url: response.data.data.fav_icon,
              }
            : null,
          login_logo: response.data.data.login_logo
            ? {
                name: response.data.data.login_logo,
                url: response.data.data.login_logo,
              }
            : null,
          header_logo: response.data.data.header_logo
            ? {
                name: response.data.data.header_logo,
                url: response.data.data.header_logo,
              }
            : null,
        });
        setLogoModeRadio(response.data.data.logo_mode);
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error, "getGeneral setting error");
    }
  };

  const updateApiKey = async (newKey, apiId) => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.updateApiKey,
        methodName: Service.putMethod,
        body: {
          id: apiId,
          api_key: newKey,
          key_for: "hrmstimesync",
        },
      });
      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setApiKey(response?.data?.data?.api_key);
        message.success(response?.data?.message);
        getApiKey();
      }
    } catch (error) {
      console.log(error, "putmethod error");
    }
  };

  const handleSettings = async (values) => {
    try {
      dispatch(showAuthLoader());
      const reqBodyNever = {
        never: true,
      };
      const reqBodyFour = {
        quarterlyMail: true,
      };
      const reqBody = {
        project_assigned: values?.projectAssigned || false,
        discussion_subscribed: values?.discussionSubscribed || false,
        discussion_tagged: values?.discussionComments || false,
        maintask_subscribed: values?.tasklistSubscribed || false,
        task_assigned: values?.taskAssigned || false,
        task_tagged_comments: values?.taskComments || false,
        bug_assigned: values?.bugAssigned || false,
        bug_tagged_comments: values?.bugComments || false,
        note_assigned: values?.noteAssigned || false,
        note_tagged_comments: values?.noteComments || false,
        file_subscribed: values?.fileSubscribed || false,
        logged_hours: values?.hoursLogged || false,
      };
      const response = await Service.makeAPICall({
        api_url: Service.updateSettings,
        methodName: Service.putMethod,
        body:
          values.notificationPreference == "Never"
            ? reqBodyNever
            : values.notificationPreference == "four_hours"
            ? reqBodyFour
            : reqBody,
      });
      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setSettingModal(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {authUser?._id && (
        <>
          {/* {getRoles(["Client"]) && <li onClick={showModal}>Change Password</li>} */}
          {!getRoles(["Client"]) && (
            <li
              onClick={() => {
                setSettingModal(true);
                emailPreference();
                generalSettingApp();
                dispatch(hideAuthLoader())
              }}
            >
              Change Password
            </li>
          )}

          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
    </ul>
  );

  const admin = [
    {
      label: getRoles(["Admin", "Super Admin"]) && (
        <Link to="/workflows">
          <span className="setting-menu">
            {" "}
            <i className="fi fi-rr-workflow-setting-alt"></i>
            WorkFlow
          </span>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to="/project-technologies">
          <span className="setting-menu">
            {" "}
            <i className="fi fi-rr-microchip"></i>
            Technologies
          </span>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link to="/manage-project-type">
          <span className="setting-menu">
            <i className="fi fi-rs-workflow-alt"></i>
            Project Types
          </span>
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link to="/project-status">
          <span className="setting-menu">
            <FaRegFileArchive />
            Status
          </span>
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Link to="/project-labels">
          <span className="setting-menu">
            <FaRegFileArchive />
            Labels
          </span>
        </Link>
      ),
      key: "5",
    },
    {
      label: (
        <Link to="/resources">
          <span className="setting-menu">
            {" "}
            <i className="fi fi-rr-poll-h"></i>
            Resource
          </span>
        </Link>
      ),
      key: "6",
    },

    {
      label: (
        <Link to="/project-archieved">
          <span className="setting-menu">
            {" "}
            <i className="fi fi-rr-poll-h"></i>
            Archived Project
          </span>
        </Link>
      ),
      key: "7",
    },
    {
      label: (
        <Link to="/trash">
          <span className="setting-menu">
            {" "}
            <i class="fa fa-trash-o"></i>
            Trash
          </span>
        </Link>
      ),
      key: "8",
    },
  ];

  const user = [
    {
      label: (
        <Link to="/project-archieved">
          <span className="setting-menu">
            {" "}
            <i className="fi fi-rr-poll-h"></i>
            Archived Project
          </span>
        </Link>
      ),
      key: "7",
    },
  ];

  const passwordRules = [
    {
      required: true,
      message: "Please enter Password",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.resolve();
        }

        const passwordPattern =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;

        if (!passwordPattern.test(value)) {
          return Promise.reject(
            new Error(
              "Password must be 8-20 char long & uppercase letter, lowercase letter, number, special character"
            )
          );
        }

        return Promise.resolve();
      },
    },
  ];

  return (
    <>
      <div className="main-heading">
        <div className="head-title">
          {/* <p>Elsner TaskHub</p> */}
          {/* <img src="/elsnerfavicondark.svg" height={30} /> */}
          {/* <img
            height={30}
            src={`${process.env.REACT_APP_API_URL}/public/${headerLogo}`}
          /> */}
        </div>
        <div className="header-wrapper">
          <div className="gx-flex-row gx-align-items-center gx-avatar-row">
            {/* <div className="time-modal">
            <i className="fi fi-rr-clock-five width-18"></i>
          </div> */}

            {/* <div className="search-pms">
            <i className="fi fi-rr-search width-18"></i>
          </div> */}
           

          

            <Popover
              placement="bottomRight"
              content={userMenuOptions}
              // visible={visiblePopOver}
              // onVisibleChange={handleVisiblePopOver}
              trigger="click"
              className="user-profile"
            >
              <div>
                <img
                  src={
                    authUser?.emp_img
                      ? `https://hrms.elsner.com/uploads/emp_images/${authUser.emp_img}`
                      : ProfileImage
                  }
                  className="avatar-user"
                  alt="User"
                />
              </div>
            </Popover>

            <Modal
              // footer={false}
              // visible={isModalOpen}
              // onOk={handleOk}
              // onCancel={handleCancel}
              //
              className="setting-main-wrapper"
              footer={false}
              visible={settingModal}
              onOk={() => {
                setSettingModal(false);
              }}
              onCancel={() => {
                setSettingModal(false);
              }}
            >
              <div className="modal-header">
                <h1>Change Password</h1>
              </div>
              <div className="overview-modal-wrapper">
                <Form
                  onFinish={(values) => {
                    changepassword(values);
                  }}
                  {...formItemLayout}
                >
                  <div className="topic-cancel-wrapper">
                    <Form.Item
                      label="Current Password"
                      name="current_password"
                      className="search-row"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Current Password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label="New Password"
                      name="new_password"
                      className="search-row"
                      rules={passwordRules}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      name="confirm_password"
                      className="search-row"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Confirm Password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The confirm passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <div className="modal-footer-flex">
                      <div className="flex-btn" style={{marginTop:'20px'}}>
                        <Button
                          key="back"
                          type="primary"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={changepassword}>
                          Change Password
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </Modal>

           
          </div>
        </div>
      </div>
    </>
  );
}

UserProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(UserProfile);
