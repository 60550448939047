import axios from "axios";
import { message } from "antd";
import getCookie from "../hooks/getCookie";
import removeCookie from "../hooks/removeCookie";
const { REACT_APP_API_URL } = process.env;
export default class Service {
  static HRMS_Base_URL = "https://hrms.elsner.com";
  static Server_Base_URL = REACT_APP_API_URL;
  static API_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL + "/v1"
      : `${this.Server_Base_URL}/v1`;

  static API_Call_Counter = 0;
  static incre_API_Call_Counter = () => this.API_Call_Counter++;
  static decre_API_Call_Counter = () =>
    (this.API_Call_Counter =
      this.API_Call_Counter > 0 ? this.API_Call_Counter - 1 : 0);

  static error_message = "Something went wrong!";
  static error_message_key = "error_message_key";

  static message_containner = [];
  static add_message = (text) => {
    var index = this.message_containner.findIndex((x) => x === text);
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      this.message_containner.push(text);
    }
    return index;
  };
  static remove_message = (message) =>
    (this.message_containner = this.message_containner.filter(
      (m) => m !== message
    ));
  static messageError = (msg) => {
    const index = this.add_message(msg);
    if (index === -1) {
      message.error(msg).then(() => {
        this.remove_message(msg);
      });
    }
  };

  static messageInfo = (msg) => {
    const index = this.add_message(msg);
    if (index === -1) {
      message.info(msg).then(() => {
        this.remove_message(msg);
      });
    }
  };

  static postMethod = "POST";
  static getMethod = "GET";
  static putMethod = "PUT";
  static deleteMethod = "DELETE";
  static headers = {
    accept: "application/json",
    "content-type": "application/json",
  };

  //Auth Module
  static login = "/admin/login";
  static userById = "/admin/userById";
  static empById = "/emp/getempbyid";
  static editAdmin = "/admin/editadminuserprofile";
  static refreshToken = "/auth/refreshToken";
  static forgotPassword = "/authentication/client/forgotPassword";
  static resetPassword = "/authentication/client/resetPassword";
  static loginWithHRMSRedirect = "/authentication/redirectToBack";
  static login = "/authentication/login";

  //icon & logo
  // static editLogo_Icon = "/adminsettings/editAdminSetting";
  // static customadminSetting = "/adminsettings/customadminSetting";
  // static getAdminSettings = "/adminsettings/adminSetting";

  // trash module

  //app setting
  static getGeneralSetting = "/app/setting/get"
  static addGeneralSetting = "/app/setting/add-edit"

  static getRolesPermission= "/roles/permissions"
 static updatePermission = "/roles/permissions-update"

  //super admin add data
  static postCompnyData='/employees/addAdmin'
static getCompnyData='/employees/getAdminDetails'
static updateEmployData='/employees/updateAdminDetails'
static deleteData='/employees/deleteAdmin'
static totalEmply='/employees/countOfAdmins'
static updatePasswordSuperAdmin='/authentication/superadmin/updatePassword'
static employeesgetAdminDataApi='/employees/getAdminData'
static disableAdmin= '/employees/disableAdmin/'

///pms-roles/permissions/
static pms_roles_permissions='/pms-roles/permissions'
static pms_roles_permissions_Update='/pms-roles/permissions-update'
  static async makeAPICall({
    props,
    methodName,
    api_url,
    body,
    params,
    options,
  }) {
    api_url = this.API_URL + api_url;

    //request interceptor to add the auth token header to requests
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers = {
            "Access-Control-Allow-Origin": "*",
            authorization: "Bearer " + accessToken,
            platform: "web-admin",
            ...options,
          };
        } else {
          config.headers = {
            platform: "web-admin",
            ...options,
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //response interceptor to refresh token on receiving token expired error
    axios.interceptors.response.use(
      (response) => {
        if (response.code == 401||response.code==403) {
          // console.log(response.data.code,'checkck');
          
          localStorage.clear();
          window.location = "/signin";
        } else {
          // console.log('checkck',response);

          return response;
        }
      },
      async function (error) {
        // const originalRequest = error.config;
      console.log(error);
      localStorage.clear();
      window.location = "/signin";
      
        // const originalRequest = error.config;
        // // let refreshToken = localStorage.getItem("refreshToken");
        // if (
        //   // refreshToken &&
        //   error?.response?.status === 401 &&
        //   !originalRequest._retry
        // ) {
        //   if (originalRequest.url.includes("/refreshToken")) {
        //     return Promise.reject(error);
        //   }
        //   originalRequest._retry = true;
        //   try {
        //     const url = Service.API_URL + Service.refreshToken;
        //     const response = await axios.post(url, {
        //       refreshToken: refreshToken,
        //     });
        //     if (response.status === 200 && response.data.authToken) {
        //       localStorage.setItem(
        //         "accessToken",
        //         response.data.authToken.accessToken
        //       );
        //       localStorage.setItem(
        //         "refreshToken",
        //         response.data.authToken.refreshToken
        //       );
        //       console.log("Access token refreshed!");
        //       const res = await axios(originalRequest);
        //       return res;
        //     } else {
        //       console.log("Refresh Token Error", error);
        //       return Promise.reject(response);
        //     }
        //   } catch (e) {
        //     return Promise.reject(e);
        //   }
        // } else {
        //   return Promise.reject(error);
        // }
      }
    );

    if (methodName === this.getMethod) {
      if (params) {
        api_url = api_url + "?" + params;
      }
      try {        
        const response = await axios.get(api_url);
        if(!api_url.includes(this.getGeneralSetting)){
          this.permissionRoleChange(response.data);
        }
        console.log(api_url,"api_url")
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.postMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.post(api_url, body, options);
        this.permissionRoleChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.putMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.put(api_url, body, options);
        this.permissionRoleChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.deleteMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.delete(api_url, { data: body });
        this.permissionRoleChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
  }

  static logOut() {
    localStorage.clear();
    window.location = "/signin";
    removeCookie("user_permission");
    removeCookie("pms_role_id");
  }

  static uuidv4() {
    return "xxxxxxxx_4xxx_yxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static permissionRoleChange(data) {
    try {
      const pms_role = getCookie("pms_role_id");
      const storage_permission = JSON.parse(getCookie("user_permission"));
      const permissions = data.permissions;

      function arraysAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
          return false;
        }
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
        return true;
      }

      if (
        (pms_role && data.pms_role_id !== pms_role) ||
        !arraysAreEqual(permissions, storage_permission)
      ) {
        this.logOut();
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
}
