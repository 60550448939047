import enUS from 'antd/es/locale/fr_FR';
import saMessages from "../locales/fr_FR.json";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: enUS,
  locale: 'fr-FR'
};
export default saLang;
